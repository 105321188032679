/* You can add global styles to this file, and also import other style files */
@import 'assets/css/shamcey.css'; // the template's style (bootstrap.css + custom styles)
@import 'assets/css/variables.css'; //Variables css values

// add your custom rules down here
.table button {
  margin: 2px;
}

.modal-dialog.modal-lg {
  max-width: 85%;
}


// SnackBar styles
.alert {
  // padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px
}

.alert_info {
  background-color: #4285f4;
  border: 2px solid #4285f4
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0
}

.close {
  font-size: 20px;
  color: #fff;
  opacity: 0.9;
  font-weight: normal
}

.alert_success {
  background-color: #127012;
  border: 2px solid #127012;
}

.alert_warning {
  background-color: #f8b15d;
  border: 2px solid #f8b15d
}

.alert_error {
  background-color: #f95668;
  border: 2px solid #f95668
}

.msgbox-area {
  font-size: inherit;
  max-height: 100%;
  position: fixed;
  bottom: 15px;
  left: 20px;
  right: 20px;
}

.msgbox-box {
  font-size: inherit;
  color: #fff;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
  animation: snackbarAppears 400ms linear;
  animation-fill-mode: forwards;
}

@keyframes snackbarAppears {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes snackbarDisappears {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.msgbox-box.msgbox-box-hide {
  animation: snackbarDisappears 256ms linear;
  animation-fill-mode: forwards;
}

.msgbox-box:last-child {
  margin: 0;
}

.msgbox-content {
  font-size: inherit;
  padding: 0.5rem;
  flex-shrink: 1;
}

.msgbox-command {
  display: flex;
}

.msgbox-close {
  color: #fff;
  font-size: inherit;
  text-decoration: none;
  margin: 0 10px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  transition: color 64ms ease-out, text-shadow 64ms ease-out;
}

.msgbox-close:hover {
  color: #efefef;
  text-shadow: 0 0 4px #fff;
}

.msgbox-close:first-child {
  margin-left: auto;
}

.msgbox-close:last-child {
  margin-right: 0;
}

@media (min-width: 481px) and (max-width: 767px) {
  .msgbox-area {
    left: 80px;
    right: 80px;
  }
}

@media (min-width: 768px) {
  .msgbox-area {
    width: 480px;
  }
}

.msgbox-area {
  font-size: 16px;
}

.msgbox-message-container {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
}

.msgbox-message-container h1,
.msgbox-message-container h3 {
  font-weight: normal;
  padding: 0.5rem 1rem;
  margin: 0;
}

.msgbox-message-container h1:first-child,
.msgbox-message-container h3:first-child {
  padding-top: 0;
}

.msgbox-message-container h1:last-child,
.msgbox-message-container h3:last-child {
  padding-bottom: 0;
}

.msgbox-message-container p {
  padding: 0.5rem 1rem;
  margin: 0;
}

.msgbox-message-container p:first-child {
  padding-top: 0;
}

.msgbox-message-container p:last-child {
  padding-bottom: 0;
}

.msgbox-message-button {
  font-size: 18px;
  font-family: inherit;
  color: white;
  background-color: #387cdc;
  width: 250px;
  border: solid 2px #2263bf;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.4rem #2263bf;
  transition: background-color 64ms ease-out, box-shadow 64ms ease-out, transform 64ms ease-out;
}

.msgbox-message-button:hover,
.msgbox-message-button:focus,
.msgbox-message-button:active {
  background-color: #4e8ae0;
}

.msgbox-message-button:active {
  background-color: #256ed5;
  box-shadow: 0 0 #2263bf;
  transform: translateY(0.4rem);
}

.menu-dropdown {
  background-color: white;
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: auto;
  z-index: 200;
  right: 0;
}

.menu-dropdown button {
  border: none;
  background: none;
  padding: 10px 20px;
  margin: 5px;
}

.menu-dropdown button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
@keyframes LIST-TABLE2-SHOW {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes LIST-TABLE2-HIDDEN {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

@keyframes LIST-TABLE2-OPENSIZE {
  from {
      opacity: 0;
      transform: translateX(-20%);
  }
  to {
      transform: translateX(0%);
      opacity: 1;
  }
}

@keyframes LIST-TABLE2-CLOSESIZE {
  from {
      opacity: 1;
      transform: translateX(0%);
  }
  to {
      opacity: 0;
      transform: translateX(-20%);
  }
}

.list-table2-show {
  animation: LIST-TABLE2-SHOW 1s both;
}

.list-table2-hidden {
  animation: LIST-TABLE2-HIDDEN 1s both;
}
.list-table2-showsize {
  animation: LIST-TABLE2-OPENSIZE 1s both;
}

.list-table2-hiddensize {
  animation: LIST-TABLE2-CLOSESIZE 1s both;
}


.list-table2-show {
  animation: LIST-TABLE2-SHOW 1s both;
}

.list-table2-hidden {
  animation: LIST-TABLE2-HIDDEN 1s both;
}
.list-table2-showsize {
  animation: LIST-TABLE2-OPENSIZE 1s both;
}

.list-table2-hiddensize {
  animation: LIST-TABLE2-CLOSESIZE 1s both;
}
.list-table3-show {
  animation: LIST-TABLE2-SHOW 1s both;
}

.list-table3-hidden {
  animation: LIST-TABLE2-HIDDEN 1s both;
}
.list-table3-showsize {
  animation: LIST-TABLE2-OPENSIZE 1s both;
}

.list-table3-hiddensize {
  animation: LIST-TABLE2-CLOSESIZE 1s both;
}

.list-table2 {
  position: absolute;
  left: 10px;
  z-index: 100;
  top:10px;
  min-width: 250px;
  width: 100%;
  max-width: 900px;
}

.list-table3 {
  position: absolute;
  left: 0px;
  z-index: 102;
  top: 0;
  min-width: 250px;
  width: 100%;
  max-width: 900px;
}

.customs{
  border-bottom: 2px solid #1c4b6b;
  color: #525353 !important;

  a:hover{
    background-color: #089244 !important;
    color: #fff !important;
    img{
      opacity: 0;
    }
  }

}

.customs > .active{
  background-color: #089244 !important;
  color: #fff !important;
}

.customs2{
  // border-bottom: 2px solid #089244;
  color: #525353 !important;

  a:hover{
    background-color: #089244 !important;
    color: #fff !important;

    img{
      opacity: 0;
    }
  }

}

.customs2 > .active{
  background-color: #089244 !important;
  color: #fff !important;
}

.image:hover{
  cursor: pointer;
}

.modal-content {
  width: 90vw !important;
  height: 100%;
  max-width: 800px !important;
  text-align: left;
  border-radius: 20px;

  .form-modal {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.primary-shadow {
  box-shadow: var(--charka-shadow-lg);
  border: var(--border-light);
  border-radius: 20px !important;
}
.text_ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.popover_small {
  .popover-content {
    width: 100%;
    right: 0% !important;
    left: 0;
    max-width: 480px;
    overflow: hidden;
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
